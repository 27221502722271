import React from 'react';
import SignUpForm from './SignUpForm';
import './App.css';

function App() {
  return (
    <div className="App">
      <SignUpForm />
    </div>
  );
}

export default App;
